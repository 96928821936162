@import '../../../../assets/css/config.scss';
.order-supplies-history {

    .ups-page-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        padding: 0em 0.5em;
        @media #{$medium-up} {
            font-size: 1rem;
            padding: 1.125em 1.5em;
        }

        .page-title {
            font-size: 1.1em;
            @media #{$medium-up} {
                font-size: 1.25em;
            }
        }
    }

    .action {
        font-size: 0.9em;
        padding: 0px 2px;
        height: 35px;
        @media #{$medium-up} {
            font-size: .8em;
            padding: 0 26px;
            height: 40px;
        }

        .material-icons {
            font-size: 15px;
            @media #{$medium-up} {
                font-size: 18px;
            }
        }
    }

    .instruction {
        color: $ups-bray;
        font-size: 0.9em; 
        width: 70%;
        padding:0em 0em 0em 1em;
        
        @media #{$medium-up} {
            width: 100%;
            padding: 0em 2.5em 4em 2.8em;            
        }
    }

    .dataTables_wrapper,
    .ngx-pagination {
        margin: 1em 0 2em !important;
        @media #{$medium-up} {
            width: 70%;
        }

        .mat-mdc-raised-button {
            font-weight: 600;
            font-size: 0.8em;
            padding: 0 8px;
            height: 35px;
            @media #{$medium-up} {
                font-size: 0.9em;
                padding: 0 15px;
                height: 35px;
            }
        }
    }

    table.dataTable thead th,
    table.dataTable thead td {
        padding: 8px 10px;
    }

    .trigger-column,
    .trigger-cell {
        display: none;
    }

    .address {
        max-width: 130px;
        display: table-cell;
        @media #{$medium-up} {
            max-width: 100%;
        }

        div {
            max-width: 130px;
            @media #{$medium-up} {
                display: inline;
            }
        }
    }
    
    .order-number {
        width: 100%;
    }

    .details {
        padding-left: 0;
    }
}


