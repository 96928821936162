@import '../../../assets/css/config.scss';
.restricted-zip-codes {
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        color: $ups-gray-dark;
        font-size: 12px;
        .instruction {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0.8em 2em;
        }
        .sections {
            display: flex;
            flex-direction: row;
            // justify-content: flex-start;
            .section {
                display: flex;
                flex-direction: column;
                // justify-content: left;
                padding: 0.8em 2em;
            }
        }
        p {
            margin: 0 0 3px;
        }
        .terms{
            padding-top:3em;
        }
    }
}