@import '../../../../assets/css/config.scss';

.order-details {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; // align-content: space-between;
    margin-top: 1.5em;
    font-size: 0.9em;

    .order-detail {
        margin-bottom: 0.5em;
        width:100%;
    }
    .order-items {
        width: 100%;
    }

    .order-date {
        display: flex;
        flex-direction: column;
        color: $ups-black-4;
    }
    .order-number {
        display: flex;
        flex-direction: column;
        color: $ups-black-4;
    }
    h4 {
        color: $ups-black-4;
    }

    .instruction {
        font-style: italic;
        font-weight: 500;
    }
    .total,
    .instruction {
        color: $ups-bray;
    }
    .total {
        margin: 1em 0 2.5em 1.5em;    
        font-weight: 600;
    }

    .deprecated-key-values {
        width: 100%;
        color: $color-text-table-content;

        .key-value {
            width: 100%;
            .key {
                width: 49%;
            }
            .value {
                justify-content: flex-start;
                font-weight: normal;
                white-space: initial;
                width: 49%;
                @media #{$medium-up} {
                    width: auto;
                }
            }
        }
    }

    .deprecated-key-values,
    .key-value {
        padding: 0;
        
    } 

    .table-wrapper {
        margin-left: 0;
        @media #{$medium-up} {
            margin-left: -3em;
        }
    }

    table.dataTable tbody th, table.dataTable tbody td{
        padding: 8px 16px;
    }
    table.dataTable thead th{
        padding: 8px 16px;
    }

    // .odd {
    //     display: none !important;
    // }
}
