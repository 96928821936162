@import "config";

.supply-order {
    @extend .page-center;

    .ups-page-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .page-title {
            font-size: 1.1em;
            @media #{$medium-up} {
                font-size: 1.25em;
            }
        }

        .page-actions{
            padding: 0.6rem 1em;
            @media #{$medium-up}{
            padding: 1rem 0;
            }

            .mat-mdc-raised-button{
                padding: 0 12px;
                height: 35px;                
                @media #{$medium-up}{
                    padding: 0px 32px;
                    height: 45px;
                }
            }
        }
        
    }

    .action{
        font-size: .8em;
    }

    .form-body {
        padding: 1.5em 0;
    }

    .supply-categories {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        .deprecated-key-values {
            width: 80%;

            @media #{$medium-up} {
                margin-right: calc(6% / 2);
                width: calc(94% / 2);
            }

            @media #{$large-up} {
                margin-right: calc(9% / 3);
                width: calc(91% / 3);
            }

            .key-value {
                width: 100%;

                .key {
                    width: 30%;
                }

                .value {
                    width: 70%;
                    justify-content: flex-end;
                }

                &.header {
                    .key {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .ship-to {
        width: 80%;
        padding: 1em;
        border: solid thin $ups-bray-lighter;
        border-radius: 3px;

        @media #{$medium-up} {
            width: 50%;
        }

        @media #{$large-up} {
            width: 30%;
        }

        h5 {
            font-weight: 700;
        }

        .address-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .last-supply-order {
        display:none;        
        margin-right: 3em;
        margin-top: 1em;

        @media #{$medium-up}{
            display: block;
        }        
    }   
    .last-supply-order-mobile{
        display:block;
        padding: 0em 1.5em 1em 1.5em;
        margin-top: -1em;
        @media #{$medium-up}{
            display: none;
        }
    }

    .last-supply-order-mobile, .last-supply-order{
        font-size: 13px;
        font-weight: normal;
        color:#707070;        

        a.date, a.detail {
            font-weight: 600;
            font-size: 14px;
        }

        a.detail{            
            text-decoration: underline;            
        }

        a.detail, a.detail:visited {
            //color: $ups-green;
        }

        .close-button .material-icons {
            vertical-align: text-bottom;
            font-size: 18px;
            color:#8f8f8f;
        }
    }
}
